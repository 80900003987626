import Button from "../../atoms/controls/Button"
import Select from "../../atoms/controls/Select"
import { useAlerts } from "../app/AppContext"
import Styles from "./Forms.module.css"
import { useState } from "react"
import { format } from "date-fns"
import axios from "axios"

export default function DatabaseControls() {
    const [selected_file, set_selected_file] = useState()
    const [submitting, set_submitting] = useState(false)
    const [database, set_database] = useState()
    const { new_alert } = useAlerts()

    function empty_file(event) {
        event.target.value = ""
        update_file(event)
    }

    function update_file(event) {
        if (!event?.target?.files || event.target.files.length === 0) return
        set_selected_file(event.target.files[0])
    }

    async function download_database() {
        try {
            const params = { type: database }
            const { data } = await axios.get("/api/database", { params })
            const link = document.createElement("a")
            link.href = URL.createObjectURL(new Blob([data]))
            link.download = `${database}_${format(Date.now(), "yyyy-MM-dd-hhmmss")}.csv`
            link.click()
        } catch (error) {
            if (!error.response) throw error
            new_alert(error.response.status, error.response.headers.reason)
        }
    }

    async function update_database(event) {
        set_submitting(true)

        event.preventDefault()
        let response

        const data = new FormData()
        data.append("file", selected_file)
        data.append("type", database)

        try {
            response = await axios.post("/api/database", data)
            event.target.reset()
        } catch (error) {
            console.log(error)
            response = error.response
        } finally {
            if (response) {
                new_alert(response.status, response.headers.reason)
            }
            set_submitting(false)
        }
    }

    return (
        <form className={`${Styles.form} ${Styles.db}`} onSubmit={update_database}>
            <Select
                id="database"
                name="database"
                onSelect={set_database}
                placeholder="Select Database"
                value={database || ""}
                required>
                {["Employees", "Sites", "Leavetypes", "StaffDictionary", "LeaveTypeDictionary", "ActionDictionary", "LeaveAdmin"].map((database) => {
                    return <option key={database} value={database}>{database}</option>
                })}
            </Select>
            <input
                type="file"
                id="file"
                name="file"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onClick={empty_file}
                onChange={update_file}
                required />
            <Button type="button" onClick={download_database}>Download</Button>

            <input type="submit" disabled style={{ display: "none" }} aria-hidden="true" /> {/* this is required to prevent user from submitting form with enter button */}
            <Button type="submit" disabled={submitting}>Update</Button>
        </form>
    )
}
