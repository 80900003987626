import DatePicker from '../../atoms/controls/DatePicker'
import Button from '../../atoms/controls/Button'
import Input from '../../atoms/controls/Input'
import { useAlerts } from "../app/AppContext"
import Styles from './Forms.module.css'
import { useState } from 'react'
import axios from 'axios'


export default function SwabCountForm() {
    const [data, set_data] = useState({})
    const { new_alert } = useAlerts()
    const [submitting, set_submitting] = useState(false)

    function update_data(property, value) {
        set_data((prev_data) => {
            return { ...prev_data, [property]: value }
        })
    }

    async function submit_data(event) {
        event.preventDefault()
        set_submitting(true)

        let response
        try {
            response = await axios.post("/api/swab_count", { data })
            set_data({})
        } catch (error) {
            response = error.response
            console.log(error)
        } finally {
            new_alert(response?.status, response?.headers?.reason)
        }
        set_submitting(false)
    }

    return (
        <form className={`${Styles.form} ${Styles.swab_count}`} onSubmit={submit_data}>
            <div className={Styles.row}>
                <div className={Styles.date}>
                    <DatePicker
                        onDateChange={(date) => { update_data("date", date) }}
                        date={data.date}
                        autoComplete="off"
                        required
                    />
                </div>
                <div>
                    <Input
                        name="PCR"
                        type="number"
                        inputMode="numeric"
                        min={0}
                        step={1}
                        max={1000}
                        placeholder="PCR"
                        onChange={(event) => { update_data(event.target.name, event.target.value) }}
                        value={data.PCR || ""}
                        required
                    />
                </div>
                <div>
                    <Input
                        name="ART"
                        type="number"
                        inputMode="numeric"
                        min={0}
                        step={1}
                        max={1000}
                        placeholder="ART"
                        onChange={(event) => { update_data(event.target.name, event.target.value) }}
                        value={data.ART || ""}
                        required
                    />
                </div>
            </div>

            <input type="submit" disabled style={{ display: "none" }} aria-hidden="true" /> {/* this is required to prevent user from submitting form with enter button */}
            <Button type="submit" disabled={submitting}>Submit</Button>
        </form>
    )
}