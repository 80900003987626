import { useAlerts } from "../../organisms/app/AppContext"
import { useEffect, useState } from "react"
import UserFormRow from "./UserFormRow"
import axios from 'axios'

export default function UserForm() {
    const {new_alert} = useAlerts()
    const [user_data, set_user_data] = useState([])

    useEffect(() => {
        async function get_users() {
            try {
                const {data: users} = await axios.get("/admin/users")
                set_user_data(users)
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }

        get_users()
    }, [])

    useEffect(() => {
        if (user_data.length === 0) set_user_data((data) => add_row(data))
    }, [user_data])

    function add_row(form_rows) {
        return [...form_rows, {Username: ""}]
    }

    function delete_row(key_to_delete) {
        set_user_data((data) => {
            let new_data = [...data]
            new_data = new_data.filter((row) => row.username !== key_to_delete)
            return new_data
        })
    }

    function update_row(key_to_update, property_to_update, value) {
        const empty_rows = user_data.filter((row_data) => (Object.values(row_data).every((value) => (value == null || value === ""))))

        set_user_data((data) => {
            let new_data = [...data]
            const index_to_update = new_data.findIndex((row) => row.username === key_to_update)
            new_data[index_to_update] = {...new_data[index_to_update], [property_to_update]: value}
            if(empty_rows.length === 0 || (empty_rows.length === 1 && empty_rows[0].username === key_to_update && value)) {
                new_data = add_row(new_data)
            }
            return new_data
        })
    }

    return (
        <div className={`section card`}>
            <h2>Users</h2>
            <hr />
            {   user_data?.length ? 
                user_data.map((user) => {
                    return <UserFormRow key={user.username} data={user} update_row={(value) => update_row(user.username, value)} delete_row={(value) => delete_row(user.username, value)}/>
                }) : null
            }
        </div>
    )   
}