import DeputyReportForm from '../organisms/forms/DeputyReportForm'
import PageStyle from './pages.module.css'

export default function DeputyReports() {
    return (
        <main>
            <div className={`section card ${PageStyle.display}`}>
                <header>
                    <h2>Reports</h2>
                </header>
                <hr />
                <DeputyReportForm />
            </div>
        </main>
    )
}