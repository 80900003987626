import { useAlerts, useSetTokens } from "../../organisms/app/AppContext"
import Styles from "./Auth.module.css"
import { useState } from 'react'
import axios from 'axios'

export default function Login() {
    const [form_data, set_form_data] = useState({})
    const set_tokens = useSetTokens()
    const { new_alert } = useAlerts()

    function update_form_data(event) {
        set_form_data({
            ...form_data, [event.target.name]: event.target.value
        })
    }

    async function post_login(event) {
        event.preventDefault()
        let response
        try {
            response = await axios.post("/auth/login", {
                username: form_data.username,
                password: form_data.password
            })
            set_tokens({
                auth_token: response.data.auth_token,
                refresh_token: response.data.refresh_token
            })
            set_form_data({})
        } catch (error) {
            response = error.response
        } finally {
            new_alert(response.status, response.headers.reason)
        }
    }

    return (
        <div className={`${Styles.auth}`}>
            <header><h1>Login</h1></header>
            <hr />
            <form onSubmit={post_login}>
                <input
                    type="text"
                    id="username"
                    name="username"
                    autoComplete="username"
                    placeholder="Username"
                    value={form_data.username || ""}
                    onChange={update_form_data}
                    required />
                <input
                    type="password"
                    id="password"
                    name="password"
                    autoComplete="password"
                    placeholder="Password"
                    value={form_data.password || ""}
                    onChange={update_form_data}
                    required />
                <input type="submit" id="submit" className={Styles.submit} value="Login" />
            </form>
        </div>
    )
}