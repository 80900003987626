import AbsentReportControls from "../../organisms/forms/AbsentReportControls"

export default function Reports() {
    return (
        <main>
            <div className="section card">
                <h2>Absent Report</h2>
                <hr />
                <AbsentReportControls />
            </div>
        </main>
    )
}