import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Styles from "./Inputs.module.css"
import { forwardRef } from "react"

export default forwardRef(function IconButton({className, icon, onClick, ...props}, ref) {

    function handle_click(event) {
        onClick && onClick(event)
    }

    return(
        <div ref={ref} className={`${Styles.icon_button} ${className}`} onClick={handle_click}>
            <FontAwesomeIcon icon={icon} {...props}/>
        </div>
    )
})