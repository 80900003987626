import DatePicker from '../../atoms/controls/DatePicker'
import Select from '../../atoms/controls/Select'
import Button from '../../atoms/controls/Button'
import { useAlerts } from '../app/AppContext'
import { useEffect, useState } from 'react'
import Styles from "./Forms.module.css"
import { format } from 'date-fns'
import axios from 'axios'

export default function DeputyReportForm() {
    const [start_date, set_start_date] = useState()
    const [end_date, set_end_date] = useState()
    const [report_type, set_report_type] = useState()
    const [submitting, set_submitting] = useState(false)
    const [report_types, set_report_types] = useState()
    const { new_alert } = useAlerts()

    useEffect(() => {
        const controller = new AbortController()
        async function get_report_types() {
            try {
                const { data } = await axios.get("/api/deputy_report/TYPES", { signal: controller.signal })
                set_report_types(data)
            } catch (error) {
                if (controller.signal.aborted) return
                if (!error.response || !error?.response?.status) throw error
                new_alert(error.response.status, error.response.headers.reason)
            }
        }
        get_report_types()
        return () => controller.abort()
    }, [new_alert])

    async function download_report() {
        try {
            set_submitting(true)
            const { data } = await axios.get("/api/deputy_report", { params: { type: report_type, start_date: start_date, end_date: end_date } })
            const link = document.createElement("a")
            link.href = URL.createObjectURL(new Blob([data]))
            link.download = `${report_type}_${format(Date.now(), "yyyy-MM-dd-hhmmss")}.csv`
            link.click()
        } catch (error) {
            if (!error.response) throw error
            new_alert(error.response.status, error.response.headers.reason)
        } finally {
            set_submitting(false)
        }
    }

    return (
        <div className={`${Styles.row}`}>
            <Select className={`${Styles.report_type_selector}`} label="Report Type" value={report_type} onSelect={set_report_type}>
                {report_types?.map(type => <option key={type} value={type}>{type}</option>)}
            </Select>
            {report_type !== "Employee" &&
                <>
                    <DatePicker className={Styles.date} label="Start Date" date={start_date} onDateChange={set_start_date} />
                    <DatePicker className={Styles.date} label="End Date" date={end_date} onDateChange={set_end_date} />
                </>
            }
            <Button className={`${Styles.report_type_selector}`} onClick={download_report} disabled={submitting}>Download</Button>
        </div>
    )
}

