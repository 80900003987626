import Styles from './Announcements.module.css'

export default function Announcements({data, className, ...props}) {
    return(
        <div className={`section card ${Styles.announcements} ${className}`} {...props}>
            <h2>Annoucements</h2>
            <hr />
            { data?.length ? data.map((announcement) => {
                return (
                    <div>
                        <header>
                            <h3>{announcement.title}</h3>
                            <span>{announcement.date}</span>
                        </header>
                        <p>{announcement.message}</p>
                    </div>
                )
            }) : 
            <span>There are no announcements</span>}
        </div>
    )
}