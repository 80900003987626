import CollapseContainer from "../collapse_container/CollapseContainer"
import Styles from "./Table.module.css"
import { startCase } from "lodash"
import { useState } from "react"

export default function TableRow({ data, headers, format, className }) {
    const [expand, set_expand] = useState(false)

    function toggle_expand() {
        set_expand((current_expand) => !current_expand)
    }

    const additional_fields = Object.keys(data).filter((field) => !headers.includes(field))

    const formatted_fields = additional_fields.reduce((formatted_fields, field) => {
        if (format == null) return formatted_fields.set(field, startCase(field))
        else {
            const only_formatted = Object.values(format).every(value => value)
            const only_unformatted = Object.values(format).every(value => !value)
            if (!Object.keys(format).includes(field)) {
                if (only_formatted) return formatted_fields.set(field, field)
                if (only_unformatted) return formatted_fields.set(field, startCase(field))
            }
            if (format[field]) {
                return formatted_fields.set(field, startCase(field))
            }
            return formatted_fields.set(field, field)
        }
    }, new Map())

    return (
        <tbody className={className}>
            <tr className={Styles.highlight} onClick={toggle_expand}>
                {headers.map((field) => <td key={field}>{data[field]}</td>)}
            </tr>
            <tr><td className={Styles.collapsed_row} colSpan="1000">
                <CollapseContainer className={Styles.content} collapse={!expand}>
                    <div className={`${Styles.additional_fields} ${Styles.table}`}>
                        {additional_fields.map(field => {
                            return (
                                <div key={field} className={Styles.additional_field_row}>
                                    <span className={Styles.field}>{formatted_fields.get(field)}</span>
                                    <span className={Styles.value}>{data[field]}</span>
                                </div>
                            )
                        })}
                    </div>
                </CollapseContainer>
            </td></tr>
        </tbody>
    )
}

