import LayoutStyles from './App.module.css'
import { Outlet } from 'react-router-dom'
import Navbar from '../navbar/Navbar'

export default function AppLayout() {
    return(
        <div id={LayoutStyles.Layout}>
            <Navbar />
            <Outlet />
            <footer>
                {/* This is a footer */}
            </footer>
        </div>
    )
}