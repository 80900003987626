import { useAlerts } from "../app/AppContext"
import DatePicker from '../../atoms/controls/DatePicker'
import TextInput from '../../atoms/controls/TextInput'
import Button from '../../atoms/controls/Button'
import Input from '../../atoms/controls/Input'
import Styles from './Forms.module.css'
import { useState } from 'react'
import axios from 'axios'
import SiteSelector from "./SiteSelector"


export default function ManpowerReportForm() {
    const [data, set_data] = useState({})
    const { new_alert } = useAlerts()
    const [submitting, set_submitting] = useState(false)
    const [site, set_site] = useState()

    function update_data(property, value) {
        set_data((prev_data) => {
            return { ...prev_data, [property]: value }
        })
    }

    function sum(array) {
        return array.reduce((sum, value) => sum += value ? +value : 0, 0)
    }

    async function submit_data(event) {
        event.preventDefault()
        set_submitting(true)

        let response
        try {
            const data_to_submit = { ...data, site_id: site?.id }
            response = await axios.post("/api/manpower_report", { data: data_to_submit })
            set_data({})
        } catch (error) {
            response = error.response
            console.log(error)
        } finally {
            new_alert(response?.status, response?.headers?.reason)
            set_submitting(false)
        }
    }

    return (
        <>
            <header className={`${Styles.header}`}>
                <h2>Manpower Report</h2>
                <SiteSelector value={site} onSelect={set_site} />
            </header>
            <hr />
            <form className={`${Styles.form} ${Styles.mr}`} onSubmit={submit_data}>
                <div className={Styles.group}>
                    <div>
                        <DatePicker
                            onDateChange={(date) => { update_data("date", date) }}
                            date={data.date}
                            autoComplete="off"
                            required
                        />
                    </div>
                    <div>
                        <TextInput
                            name="lab"
                            placeholder="Lab"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.lab || ""}
                            required
                        />
                    </div>
                </div>

                <div className={Styles.group}>
                    <header>Number of staff rostered</header>
                    <div className={Styles.row}>
                        <Input
                            name="rostered_SS"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SS"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.rostered_SS || ""}
                            required
                        />
                        <Input
                            name="rostered_PI"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="PI"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.rostered_PI || ""}
                            required
                        />
                        <Input
                            name="rostered_SW"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SW"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.rostered_SW || ""}
                            required
                        />
                        <Input
                            type="number"
                            value={sum([data.rostered_SS, data.rostered_SW, data.rostered_PI])}
                            disabled
                        />
                    </div>
                </div>

                <div className={Styles.group}>
                    <header>Number of staff reported for work</header>
                    <div className={Styles.row}>
                        <Input
                            name="reported_SS"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SS"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.reported_SS || ""}
                            required
                        />
                        <Input
                            name="reported_PI"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="PI"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.reported_PI || ""}
                            required
                        />
                        <Input
                            name="reported_SW"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SW"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.reported_SW || ""}
                            required
                        />
                        <Input
                            type="number"
                            value={sum([data.reported_SS, data.reported_SW, data.reported_PI])}
                            disabled
                        />
                    </div>
                </div>

                <div className={Styles.group}>
                    <header>Number of staff on standby</header>
                    <div className={Styles.row}>
                        <Input
                            name="standby_SS"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SS"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.standby_SS || ""}
                            required
                        />
                        <Input
                            name="standby_PI"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="PI"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.standby_PI || ""}
                            required
                        />
                        <Input
                            name="standby_SW"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SW"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.standby_SW || ""}
                            required
                        />
                        <Input
                            type="number"
                            value={sum([data.standby_SS, data.standby_SW, data.standby_PI])}
                            disabled
                        />
                    </div>
                </div>

                <div className={Styles.group}>
                    <header>Number of staff required</header>
                    <div className={Styles.row}>
                        <Input
                            name="minimum_SS"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SS"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.minimum_SS || ""}
                            required
                        />
                        <Input
                            name="minimum_PI"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="PI"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.minimum_PI || ""}
                            required
                        />
                        <Input
                            name="minimum_SW"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SW"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.minimum_SW || ""}
                            required
                        />
                        <Input
                            type="number"
                            value={sum([data.minimum_SS, data.minimum_SW, data.minimum_PI])}
                            disabled
                        />
                    </div>
                </div>

                <div className={Styles.group}>
                    <header>Number of staff supporting other sites</header>
                    <div className={Styles.row}>
                        <Input
                            name="loaned_SS"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SS"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.loaned_SS || ""}
                            required
                        />
                        <Input
                            name="loaned_PI"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="PI"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.loaned_PI || ""}
                            required
                        />
                        <Input
                            name="loaned_SW"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SW"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.loaned_SW || ""}
                            required
                        />
                        <Input
                            type="number"
                            value={sum([data.loaned_SS, data.loaned_SW, data.loaned_PI])}
                            disabled
                        />
                    </div>
                </div>

                <div className={Styles.group}>
                    <header>Number of staff supporting from other sites</header>
                    <div className={Styles.row}>
                        <Input
                            name="borrowed_SS"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SS"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.borrowed_SS || ""}
                            required
                        />
                        <Input
                            name="borrowed_PI"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="PI"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.borrowed_PI || ""}
                            required
                        />
                        <Input
                            name="borrowed_SW"
                            type="number"
                            inputMode="numeric"
                            min={0}
                            step={1}
                            max={100}
                            placeholder="SW"
                            onChange={(event) => { update_data(event.target.name, event.target.value) }}
                            value={data.borrowed_SW || ""}
                            required
                        />
                        <Input
                            type="number"
                            value={sum([data.borrowed_SS, data.borrowed_SW, data.borrowed_PI])}
                            disabled
                        />
                    </div>
                </div>
                <input type="submit" disabled style={{ display: "none" }} aria-hidden="true" /> {/* this is required to prevent user from submitting form with enter button */}
                <Button type="submit" disabled={submitting}>Submit</Button>
            </form>
        </>
    )
}