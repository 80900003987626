import Styles from './LeaveSyncUpload.module.css'
import Button from "../../atoms/controls/Button"
import Select from '../../atoms/controls/Select'
import { useAlerts } from "../app/AppContext"
import { useState, useEffect } from 'react'
import { format } from 'date-fns'
import axios from 'axios'

export default function LeaveEntitlementUpload() {
    const [is_uploading, set_is_uploading] = useState(false)
    const [selected_mode, set_selected_mode] = useState()
    const [selected_file, set_selected_file] = useState()
    const [modes, set_modes] = useState([])
    const { new_alert } = useAlerts()

    useEffect(() => {
        const controller = new AbortController()
        async function get_modes() {
            const { data } = await axios.get("/api/leave_entitlement/TYPES", { signal: controller.signal })
            set_modes(data)
        }
        get_modes()

        return () => controller.abort()
    }, [])

    function empty_file(event) {
        event.target.value = ""
        update_file(event)
    }

    function update_file(event) {
        // if there is no file selected, do not update
        if (!event?.target?.files || event.target.files.length === 0) return
        set_selected_file(event.target.files[0])
    }

    async function download_template(event) {
        event.preventDefault()
        if (!selected_mode) return new_alert(700, "select a mode")

        const { data } = await axios.get(`/api/leave_entitlement/${selected_mode.toLowerCase()}/template`)
        const link = document.createElement("a")
        link.href = URL.createObjectURL(new Blob([data]))
        link.download = `${selected_mode.toLowerCase()}_entitlement_template.csv`
        link.click()
    }

    async function upload_file(event) {
        event.preventDefault()
        if (!selected_mode) return new_alert(700, "select a mode")

        set_is_uploading(true)
        let response

        const data = new FormData()
        data.append("file", selected_file)

        try {
            response = await axios.post(`/api/leave_entitlement/${selected_mode.toLowerCase()}`, data)
            const link = document.createElement("a")
            link.href = URL.createObjectURL(new Blob([response.data]))
            link.download = `${selected_mode.toLowerCase()}_entitlement_report_${format(Date.now(), "yyyy-MM-dd-hhmmss")}.csv`
            link.click()
            event.target.reset()
        } catch (error) {
            console.log(error)
            response = error.response
        } finally {
            if (response) {
                new_alert(response.status, response.headers.reason)
            }
            set_is_uploading(false)
        }
    }

    return (
        <div className={`section card ${Styles.input}`}>
            <h2>Leave Entitlement</h2>
            <hr />
            <form className={Styles.form} onSubmit={upload_file}>
                <Select placeholder="Mode" value={selected_mode} onSelect={set_selected_mode}>
                    {modes.map((mode, index) => <option key={index} value={mode}>{mode}</option>)}
                </Select>
                <input
                    type="file"
                    id="file"
                    name="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onClick={empty_file}
                    onChange={update_file}
                    required />
                <Button type="button" onClick={download_template}>Download Template</Button>
                <Button type="submit" disabled={is_uploading}>Upload</Button>
            </form>
        </div>
    )
}