import UpdatePassword from "../pages/auth/UpdatePassword"
import CreateAccount from "../pages/auth/CreateAccount"
import ManpowerReport from "../pages/ManpowerReport"
import DeputyReports from "../pages/DeputyReports"
import AbsentReport from "../pages/AbsentReport"
import QueueTimes from "../pages/QueueTimes"
import Reports from "../pages/admin/Reports"
import LeaveSync from "../pages/LeaveSync"
import SwabCount from "../pages/SwabCount"
import Users from "../pages/admin/Users"
import Database from "../pages/Database"
import Home from "../pages/home/Home"
import axios from 'axios'
import LeaveEntitlement from "../pages/LeaveEntitlement"
import DeleteDeputyData from "../pages/DeleteDeputyData"

const routes = [
    {
        path: "/",
        component: <Home />,
    },
    {
        path: "/users",
        component: <Users />,
        title: "Users",
        permissions: {
            action: "createAny",
            resource: "account"
        }
    },
    {
        path: "/create_account",
        component: <CreateAccount />,
        title: "Create Account",
        permissions: {
            action: "createAny",
            resource: "account"
        }
    },
    {
        path: "/update_password",
        component: <UpdatePassword />,
        title: "Update Password",
        permissions: {
            action: "updateOwn",
            resource: "account"
        }
    },
    {
        path: "/leave_sync",
        component: <LeaveSync />,
        title: "Leave Sync",
        permissions: {
            action: "createAny",
            resource: "leave"
        }
    },
    {
        path: "/absent_report",
        component: <AbsentReport />,
        title: "Absent Report",
        permissions: {
            action: "readOwn",
            resource: "staff data"
        }
    },
    {
        path: "/manpower_report",
        component: <ManpowerReport />,
        title: "Manpower Report",
        permissions: {
            action: "readAny",
            resource: "site report"
        }
    },
    {
        path: "/swab_count",
        component: <SwabCount />,
        title: "Swab Count",
        permissions: {
            action: "readAny",
            resource: "site report"
        }
    },
    {
        path: "/queue_times",
        component: <QueueTimes />,
        title: "Queue Times",
        permissions: {
            action: "readAny",
            resource: "site report"
        }
    },
    {
        path: "/report_controls",
        component: <Reports />,
        title: "Report Controls",
        permissions: {
            action: "createAny",
            resource: "account"
        }
    },
    {
        path: "/database",
        component: <Database />,
        title: "Database",
        permissions: {
            action: "readOwn",
            resource: "database"
        }
    },
    {
        path: "/deputy/reports",
        component: <DeputyReports />,
        title: "Deputy Reports",
        permissions: {
            action: "readOwn",
            resource: "deputy report"
        }
    },
    {
        path: "/deputy/leave_entitlement",
        component: <LeaveEntitlement />,
        title: "Leave Entitlement",
        permissions: {
            action: "readOwn",
            resource: "database"
        }
    },
    {
        path: "/deputy/delete_data",
        component: <DeleteDeputyData />,
        title: "Delete Deputy Data",
        permissions: {
            action: "readOwn",
            resource: "database"
        }
    }
]

export default async function allowed_routes() {
    try {
        const response = await axios.post("/auth/check_permissions", {
            array: routes
        })
        const routes_in_response = response.data
        const allowed_routes = routes.filter(({ path }) => routes_in_response.some(({ path: allowed_path }) => path === allowed_path))
        return allowed_routes
    } catch (error) {
        return error
    }
}