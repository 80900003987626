import { useEffect, useState, useLayoutEffect, useRef } from "react"
import { faXmark } from "@fortawesome/free-solid-svg-icons"
import IconButton from "../../atoms/controls/IconButton"
import { useAlerts } from "../app/AppContext"
import Styles from "./Alert.module.css"
import { gsap } from "gsap"

export default function Alert({ handle, code, message }) {
    const [category, set_category] = useState("ok")
    const { clear_alert } = useAlerts()
    const alert_ref = useRef()
    const flash_timeline = useRef()
    const countdown_timeline = useRef()

    useLayoutEffect(() => {
        const context = gsap.context(() => {
            flash_timeline.current && flash_timeline.current.progress(0).kill()
            flash_timeline.current = gsap.timeline()
                .fromTo(alert_ref.current, { autoAlpha: 1 }, { autoAlpha: 0.5, duration: 0.1, yoyo: true, repeat: 3, ease: "power1.inOut" })

            countdown_timeline.current && countdown_timeline.current.progress(0).kill()
            countdown_timeline.current = gsap.timeline({ defaults: { ease: "power1.inOut" }, onComplete: () => clear_alert(handle) })
                .fromTo(`.${Styles.timer}`, { clipPath: "inset(calc(100% - 4px) 0 0 0)" }, { clipPath: "inset(calc(100% - 4px) 100% 0 0)", duration: 5, ease: "none" })
                .fromTo(alert_ref.current, { autoAlpha: 1 }, { autoAlpha: 0, duration: 0.5 })

            return (() => {
                countdown_timeline.current.eventCallback("onComplete", null)
            })
        }, alert_ref);
        return () => {
            context.revert()
        };
    }, []);

    useEffect(() => {
        if (code.toString().startsWith("4")) console.log(`[${code}] ${message}`)
        switch (code.toString()[0]) {
            case "2":
                set_category("ok")
                break
            case "4":
            case "5":
                set_category("error")
                break
            case "6":
                set_category("action")
                break
            case "7":
                set_category("warn")
                break
            default:
        }
    }, [])


    function start_countdown() {
        countdown_timeline.current.play()
    }

    function reset_countdown() {
        countdown_timeline.current.pause(0)
    }

    if (!code || !message) {
        return null
    }

    return (
        <div ref={alert_ref} data-category={category} className={`${Styles.alert}`}
            onMouseOver={reset_countdown}
            onMouseLeave={start_countdown}>
            <span>{message}</span>
            <IconButton className={Styles.close_button} icon={faXmark} onClick={() => clear_alert(handle)} />
            <div className={`${Styles.timer}`}></div>
        </div>
    )
}