import { faCalendar } from '@fortawesome/free-regular-svg-icons'
import { useState, useEffect, useRef } from "react"
import Calendar from "../calendar/Calendar"
import Styles from "./Inputs.module.css"
import IconButton from './IconButton'
import DateInput from "./DateInput"

export default function DatePicker({ date, onDateChange, className, ...props }) {
    const [show_calendar, set_show_calendar] = useState(false)
    const [align, set_align] = useState("right")
    const calendar_ref = useRef()
    const calendar_icon_ref = useRef()

    useEffect(() => {
        window.addEventListener("click", check_focus_loss)
        return () => {
            window.removeEventListener("click", check_focus_loss)
        }
    }, [])

    function handle_change(date) {
        onDateChange && onDateChange(date)
    }

    function check_focus_loss(event) {
        if (calendar_ref.current && !calendar_ref.current.contains(event.target) && calendar_icon_ref.current && !calendar_icon_ref.current.contains(event.target)) {
            set_show_calendar(false)
        }
    }

    function toggle_calendar() {
        set_show_calendar(!show_calendar)

        if (!calendar_ref?.current) return

        const rect = calendar_ref.current.getBoundingClientRect()
        if (rect.left - rect.width < 0) {
            set_align("left")
        } else {
            set_align("right")
        }
    }

    return (
        <div className={`${Styles.picker} ${className}`} tabIndex={-1}>
            <DateInput className={Styles.date} date={date} onDateChange={handle_change} tabIndex={0} {...props}/>
            <Calendar className={`${Styles.calendar} ${Styles[align]} ${show_calendar && Styles.show}`} ref={calendar_ref} date={date} onDateChange={handle_change} tabIndex={-1}/>
            <IconButton className={Styles.calendar_button} ref={calendar_icon_ref} icon={faCalendar} onClick={toggle_calendar}/>
        </div>
    )
}