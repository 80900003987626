import TextInput from '../../atoms/controls/TextInput'
import Button from "../../atoms/controls/Button"
import Styles from './Forms.module.css'

export default function UserFormRow({data, update_row, delete_row, editable, ...props}) {
    return (
        <div className={Styles.row} {...props}>
            <div>
                <TextInput 
                name="username" 
                placeholder="Username" 
                onChange={(event) => update_row("username", event.target.value)}
                value={data.username || ""}
                autoComplete='off'
                readonly={!editable}
                />
            </div>

            <div>
                <TextInput 
                name="role" 
                placeholder="Role" 
                onChange={(event) => update_row("role", event.target.value)}
                value={data.role || ""}
                autoComplete='off'
                readonly={!editable}
                />
            </div>

            <div>
                <TextInput 
                name="site_id" 
                placeholder="Site ID" 
                onChange={(event) => update_row("site_id", event.target.value)}
                value={data.site_id || ""}
                autoComplete='off'
                readonly={!editable}
                />
            </div>

            <div>
                <TextInput 
                name="organisation" 
                placeholder="Organisation" 
                onChange={(event) => update_row("organisation", event.target.value)}
                value={data.organisation || ""}
                autoComplete='off'
                readonly={!editable}
                />
            </div>

            <div>
                <TextInput 
                name="enabled" 
                placeholder="Enabled" 
                onChange={(event) => update_row("enabled", event.target.value)}
                value={data.enabled || ""}
                autoComplete='off'
                readonly={!editable}
                />
            </div>

            <div>
                <TextInput 
                name="password_updated" 
                placeholder="Password Updated" 
                onChange={(event) => update_row("password_updated", event.target.value)}
                value={data.password_updated || ""}
                autoComplete='off'
                readonly={!editable}
                />
            </div>
            
            { delete_row ? <Button type="button" onClick={delete_row}>Delete</Button> : null }
        </div>
    )   
}