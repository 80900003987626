import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { useSetTokens, useAlerts } from "../app/AppContext"
import IconButton from "../../atoms/controls/IconButton"
import Styles from "./Logout.module.css"
import axios from "axios"

export default function Logout({ className }) {
    const set_tokens = useSetTokens()
    const { new_alert } = useAlerts()

    async function logout(event) {
        event.preventDefault()
        let response
        try {
            response = await axios.post("/auth/logout")
            set_tokens("LOGOUT")
        } catch (error) {
            response = error.response
        } finally {
            new_alert(response.status, response.headers.reason)
        }
    }

    return <IconButton className={`${Styles.logout} ${className}`} icon={faRightFromBracket} onClick={logout} />
}