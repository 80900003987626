import AppContext from './organisms/app/AppContext'
import Router from './organisms/app/Router'
import ReactDOM from 'react-dom/client'
import React from 'react'
import './index.css'
import "./App.css"

function set_document_height() {
  document.documentElement.style.setProperty("--document-height", `${window.innerHeight}px`)
}
window.addEventListener("resize", set_document_height)
set_document_height()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <AppContext>
      <Router />
    </AppContext>
  </React.StrictMode>
)