import { BrowserRouter, Routes, Route } from "react-router-dom"
import { useRoutes } from "./AppContext"
import Error from "../../pages/error/Error"
import AppLayout from "./AppLayout"

export default function Router() {
    const routes = useRoutes()

    return(
        <BrowserRouter>
            <Routes>
                <Route element={<AppLayout />}>
                    {routes.map(({path, component}) => {
                        return <Route key={path} path={path} element={component}/>
                    })}
                    <Route path="*" element={<Error />} />
                </Route>
            </Routes>
        </BrowserRouter>
    )
}