import { forwardRef, useState } from "react"
import PageControls from "./PageControls"
import Styles from "./Table.module.css"
import { startCase } from "lodash"
import TableRow from "./TableRow"

export default forwardRef(function Table({ data, className, options }, ref) {
    const [page, set_page] = useState(1)
    const page_size = 50

    if (data === []) {
        return <div ref={ref} className={Styles.no_data}>There is no data to display</div>
    }

    if (data == null) {
        return <div ref={ref} />
    }

    const all_fields = Array.from(data.reduce((fields, row) => {
        return new Set([...fields, ...Object.keys(row)])
    }, new Set()))

    const headers = all_fields.filter(field => {
        if (options?.header == null) return true
        else {
            let matched_headers
            if (Object.values(options.header).every(value => typeof value === "boolean")) matched_headers = options.header
            else {
                const matched_condition = Object.keys(options.header).find(condition => eval(`${window.innerWidth}${condition}`))
                if (!matched_condition) return true
                matched_headers = options.header[matched_condition]
            }

            const only_show = Object.values(matched_headers).every(value => value)
            const only_hide = Object.values(matched_headers).every(value => !value)
            if (!Object.keys(matched_headers).includes(field)) {
                if (only_show) return false
                if (only_hide) return true
            }
            return matched_headers[field]
        }
    })

    const formatted_headers = headers.reduce((formatted_fields, field) => {
        if (options?.format == null) return formatted_fields.set(field, startCase(field))
        else {
            const only_formatted = Object.values(options.format).every(value => value)
            const only_unformatted = Object.values(options.format).every(value => !value)
            if (!Object.keys(options.format).includes(field)) {
                if (only_formatted) return formatted_fields.set(field, field)
                if (only_unformatted) return formatted_fields.set(field, startCase(field))
            }
            if (options.format[field]) {
                return formatted_fields.set(field, startCase(field))
            }
            return formatted_fields.set(field, field)
        }
    }, new Map())

    const max_pages = Math.ceil(Object.values(data).length / page_size)

    return (
        <>
            <PageControls page={page} set_page={set_page} max_pages={max_pages} />
            {max_pages > 1 ? <hr /> : null}
            <table className={`${Styles.table} ${className}`} ref={ref}>
                <thead><tr>
                    {headers.map((field) => <th key={field}>{formatted_headers.get(field)}</th>)}
                </tr></thead>
                {Object.values(data).slice((page - 1) * page_size, page * page_size).map(row => <TableRow data={row} headers={headers} format={options?.format} />)}
            </table>
            <PageControls page={page} set_page={set_page} max_pages={max_pages} />
        </>
    )
})