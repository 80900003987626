import { format, parseISO, formatISO, isAfter, endOfToday } from "date-fns"
import AbsentReportFormRow from "./AbsentReportFormRow"
import Button from "../../atoms/controls/Button"
import { useAlerts } from "../app/AppContext"
import { useEffect, useState } from "react"
import SiteSelector from "./SiteSelector"
import Styles from "./Forms.module.css"
import axios from "axios"

export default function AbsentReportForm() {
    const { new_alert } = useAlerts()
    const [active_element, set_active_element] = useState()
    const [form_data, set_form_data] = useState([])
    const [leave_types, set_leave_types] = useState([])
    const [staff_list, set_staff_list] = useState([])
    const [shifts, set_shifts] = useState([])
    const [submitting, set_submitting] = useState(false)
    const [site, set_site] = useState()

    useEffect(() => {
        if (form_data.length === 0) set_form_data(data => add_row(data))

        async function get_leave_types() {
            try {
                const { data: leave_types } = await axios.get("/api/leave_types")
                set_leave_types(leave_types)
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }

        async function get_shifts() {
            try {
                const { data: shifts } = await axios.get("/api/staff_shifts")
                set_shifts(shifts)
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }

        get_leave_types()
        get_shifts()
    }, [])

    useEffect(() => {
        async function get_staff_list() {
            try {
                const { data: staff_list } = await axios.get("/api/staff_list", { params: { site: site?.id } })
                set_staff_list(staff_list)
            } catch (error) {
                console.log(error)
                console.log(error.response)
            }
        }

        get_staff_list()
    }, [site])

    useEffect(() => {
        if (active_element != null) {
            active_element.focus()
            set_active_element()
        }
    }, [form_data])

    function add_row(form_rows) {
        const row_key = [...Array(5)]
            .map(() => Math.random().toString(16)[2])
            .join("")
            .toUpperCase()
        return [...form_rows, [row_key, {}]]
    }

    function delete_row(key_to_delete) {
        set_form_data(data => {
            let new_data = [...data]
            new_data = new_data.filter(row => row[0] !== key_to_delete)
            if (new_data.length === 0) {
                new_data = add_row(new_data)
            }
            return new_data
        })
    }

    function update_row(key_to_update, property_to_update, value) {
        const empty_rows = form_data.filter(([_, row_data]) => Object.values(row_data).every(value => value == null || value === ""))

        set_active_element(document.activeElement)
        set_form_data(data => {
            let new_data = [...data]
            const index_to_update = new_data.findIndex(row => row[0] === key_to_update)
            new_data[index_to_update] = [key_to_update, { ...new_data[index_to_update][1], [property_to_update]: value }]
            if (empty_rows.length === 0 || (empty_rows.length === 1 && empty_rows[0][0] === key_to_update && value)) {
                new_data = add_row(new_data)
            }
            return new_data
        })
    }

    async function submit_data(event) {
        event.preventDefault()
        let validation_failed = false
        set_submitting(true)

        // checking for empty form
        if (form_data.every(row => Object.values(row[1]).every(value => value == null || value === ""))) {
            set_submitting(false)
            return new_alert(700, "empty form, no data submitted")
        }

        // checking if any rows are for future dates
        const future_date_report = Object.values(form_data).reduce((future_dates, [, { date }], index) => {
            if (date == null) return future_dates
            if (isAfter(date, endOfToday())) {
                future_dates.push([index + 1, date])
            }
            return future_dates
        }, [])

        if (future_date_report.length > 0) {
            validation_failed = true
            new_alert(
                400,
                future_date_report.map(([row, date]) => `Unable to submit row with future date "${format(date, "dd MMM yyyy")}" ( row ${row} )\n`)
            )
        }

        // checking if any staff has more than one row for a given date
        const date_rows = Object.values(form_data).reduce((duplicate_dates, [, { staff_id, staff_name, date }], index) => {
            if (staff_id == null) return duplicate_dates
            if (duplicate_dates?.[[staff_id, staff_name, formatISO(date)]] == null) duplicate_dates = { [[staff_id, staff_name, formatISO(date)]]: [], ...duplicate_dates }
            duplicate_dates[[staff_id, staff_name, formatISO(date)]].push(index + 1)
            return duplicate_dates
        }, {})

        const duplicate_dates = Object.entries(date_rows).filter(([_, rows]) => rows.length > 1)
        const duplicate_date_report = duplicate_dates.map(([key, rows]) => {
            const row_data = key.split(",")
            const name = row_data[1]
            const date = parseISO(row_data[2])
            return { name, date, rows }
        })

        if (duplicate_date_report.length > 0) {
            validation_failed = true
            new_alert(
                400,
                duplicate_date_report.map(({ name, date, rows }) => `${name} has multiple statuses for ${format(date, "dd MMM yyyy")} ( rows ${rows.join(", ")} )\n`)
            )
        }

        // checking if leave_type is valid
        const invalid_leave_types_report = Object.values(form_data).reduce((invalid_leave_types, [, { staff_name, leave_type }], index) => {
            if (staff_name == null) return invalid_leave_types
            if (!leave_types.includes(leave_type)) {
                invalid_leave_types.push([index + 1, staff_name, leave_type])
            }
            return invalid_leave_types
        }, [])

        if (invalid_leave_types_report.length > 0) {
            validation_failed = true
            new_alert(
                400,
                invalid_leave_types_report.map(([row, staff, leave_type]) => `"${leave_type}" is not a valid status for ${staff} ( row ${row} )\n`)
            )
        }

        // checking if shift is valid
        let valid_shifts = {}

        for (const [, { staff_id }] of form_data) {
            if (staff_id == null) continue
            const { data } = await axios.get("/api/staff_shifts", { params: { staff: staff_id } })
            valid_shifts[staff_id] = data
        }

        const invalid_shifts_report = Object.values(form_data).reduce((invalid_shifts, [, { staff_id, staff_name, shift }], index) => {
            if (staff_id == null) return invalid_shifts
            if (!valid_shifts[staff_id].includes(shift)) {
                invalid_shifts.push([index + 1, staff_name, shift])
            }
            return invalid_shifts
        }, [])

        if (invalid_shifts_report.length > 0) {
            validation_failed = true
            new_alert(
                400,
                invalid_shifts_report.map(([row, staff, shift]) => `"${shift}" is not a valid shift for ${staff} ( row ${row} )\n`)
            )
        }

        if (validation_failed) {
            set_submitting(false)
            return
        }

        let data_to_submit = []
        form_data.forEach(row => {
            if (Object.keys(row[1]).length === 0) return
            let row_to_submit = {}
            row_to_submit["staff_id"] = row[1]["staff_id"]
            row_to_submit["leave_date"] = row[1]["date"]
            row_to_submit["leave_type"] = row[1]["leave_type"]
            row_to_submit["shift"] = row[1]["shift"]
            row_to_submit["remarks"] = row[1]["remarks"]
            data_to_submit.push(row_to_submit)
        })

        let response
        try {
            response = await axios.post("/api/absent_report", {
                data: data_to_submit,
                site_id: site.id,
            })
            set_form_data(add_row([]))
        } catch (error) {
            response = error.response
            console.log(error)
        } finally {
            new_alert(response?.status, response?.headers?.reason)
            set_submitting(false)
        }
    }

    return (
        <>
            <header className={`${Styles.header}`}>
                <h2>Absent Report</h2>
                <SiteSelector value={site} onSelect={set_site} />
            </header>
            <hr />
            <form className={`${Styles.form} ${Styles.absent_report}`} onSubmit={submit_data}>
                {form_data.map(([key, data]) => {
                    return (
                        <AbsentReportFormRow
                            key={key}
                            data={data}
                            update_data={(updated_variable, updated_value) => update_row(key, updated_variable, updated_value)}
                            delete_row={() => delete_row(key)}
                            staff_list={staff_list}
                            leave_types={leave_types}
                            shifts={shifts}
                        />
                    )
                })}
                <input type="submit" disabled style={{ display: "none" }} aria-hidden="true" /> {/* this is required to prevent user from submitting form with enter button */}
                <Button type="submit" disabled={submitting}>
                    Submit
                </Button>
            </form>

        </>
    )
}
