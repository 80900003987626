import Button from "../../atoms/controls/Button"
import Select from "../../atoms/controls/Select"
import Input from "../../atoms/controls/Input"
import { useAlerts } from "../app/AppContext"
import Styles from "./Forms.module.css"
import { useState } from "react"
import axios from "axios"

export default function DeleteForm() {
    const [ids, set_ids] = useState("")
    const [submitting, set_submitting] = useState(false)
    const [type, set_type] = useState("")
    const { new_alert } = useAlerts()

    async function delete_deputy_data(event) {
        set_submitting(true)

        event.preventDefault()
        let response

        try {
            response = await axios.delete(`/api/deputy_data`, {params: {ids, type}})
            event.target.reset()
        } catch (error) {
            console.log(error)
            response = error.response
        } finally {
            if (response) {
                new_alert(response.status, response.headers.reason)
            }
            set_submitting(false)
        }
    }

    return (
        <form className={`${Styles.form} ${Styles.db}`} onSubmit={delete_deputy_data}>
            <Select
                id="type"
                name="type"
                onSelect={set_type}
                placeholder="Select Type"
                value={type || ""}
                required>
                {["Leave", "Shift", "Timesheet"].map((type) => {
                    return <option key={type} value={type}>{type}</option>
                })}
            </Select>
            <Input
                className={Styles.ids}
                id="ids"
                name="ids"
                placeholder="Enter Comma/Whitespace Seperated IDs"
                onChange={(event) => set_ids(event.target.value)}
                autoComplete="off"
                required />

            <input type="submit" disabled style={{ display: "none" }} aria-hidden="true" /> {/* this is required to prevent user from submitting form with enter button */}
            <Button type="submit" disabled={submitting}>Delete</Button>
        </form>
    )
}
