import { useAlerts } from "../../organisms/app/AppContext"
import Styles from './LeaveSyncUpload.module.css'
import Button from "../../atoms/controls/Button"
import { useState } from 'react'
import axios from 'axios'

export default function UploadLeaveSync() {
    const [is_uploading, set_is_uploading] = useState(false)
    const [selected_file, set_selected_file] = useState()
    const { new_alert } = useAlerts()

    function empty_file(event) {
        event.target.value = ""
        update_file(event)
    }

    function update_file(event) {
        // if there is no file selected, do not update
        if (!event?.target?.files || event.target.files.length === 0) return
        set_selected_file(event.target.files[0])
    }

    async function upload_file(event) {
        set_is_uploading(true)
        event.preventDefault()
        let response
        
        const data = new FormData()
        data.append("file", selected_file)

        try {
            response = await axios.post("/api/leave_sync", data)
            event.target.reset()
        } catch (error) {
            console.log(error)
            response = error.response
        } finally {
            if (response) {
                new_alert(response.status, response.headers.reason)
            }
            set_is_uploading(false)
        }
    }

    return (
        <div className={`section card ${Styles.input}`}>
            <h2>Leave Sync</h2>
            <hr />
            <form className={Styles.form} onSubmit={upload_file}>
                <input
                    type="file"
                    id="file"
                    name="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    onClick={empty_file}
                    onChange={update_file}
                    required />
                <Button type="submit" disabled={is_uploading}>Upload</Button>
            </form>
        </div>
    )
}