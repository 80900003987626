import Announcements from "../../organisms/announcements/Announcements"
import { useState, useEffect } from "react"
import axios from 'axios'

export default function Home() {
    const [announcements, set_announcements] = useState([])

    useEffect(() => {
        async function get_announcements() {
            try {
                const { data } = await axios.get("/api/announcements")
                set_announcements(data)
            } catch (error) {
                console.log(error)
            }
        }

        get_announcements()
    }, [])

    return(
        <main>
            <Announcements data={announcements}/>
        </main>
    )
}