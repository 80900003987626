import { parse, format, isValid } from "date-fns"
import { useEffect, useState } from "react"
import TextInput from "./TextInput"

export default function DateInput({ date, onDateChange, className, ...props }) {
    const [display_date, set_display_date] = useState("")

    useEffect(() => {
        let datestring = ""
        if(isValid(date)) {
            datestring = format(date, "dd MMM yyyy")
        }
        set_display_date(datestring)
    }, [date])

    function fuzzy_intepret_datestring(datestring) {
        const formats = [
            "d M",
            "d MMM",
            "d MMMMM",
            "d M yy",
            "d MMM yy",
            "d MMMM yy",
            "d M yyyy",
            "d MMM yyyy",
            "d MMMM yyyy",
            "M d",
            "MMM d",
            "MMMM d",
            "M d yy",
            "MMM d yy",
            "MMMM d yy",
            "M d yyyy",
            "MMM d yyyy",
            "MMMM d yyyy",
            "MMM",
            "MMMM",
            "MMM yy",
            "MMMM yy",
            "MMM yyyy",
            "MMMM yyyy"
        ]
        let date = parse("", formats[0], Date.now())
        let index = 0

        if (datestring !== "" && datestring != null) {
            const cleaned_datestring = datestring.trim().split(/\/|-|\s|\\|\./).join(" ")
    
            while (date.toString() === "Invalid Date" && index < formats.length) {
                date = parse(cleaned_datestring, formats[index], Date.now())
                index++
            }
        }
        return date
    }

    function handle_change(event, interpret=false) {
        if (interpret) {
            let datestring = ""
            const interpreted_date = fuzzy_intepret_datestring(event.target.value)
            if (interpreted_date.toString() !== "Invalid Date") {
                datestring = format(interpreted_date, "dd MMM yyyy")
            }
            event.target.value = datestring
        }
        set_display_date(event.target.value)
    }
    
    function update_parent(event) {
        handle_change(event, true)
        
        let interpreted_date = fuzzy_intepret_datestring(event.target.value)
        if (interpreted_date.toString() === "Invalid Date") {
            interpreted_date = undefined
        }
        onDateChange && onDateChange(interpreted_date)
    }

    return (
        <TextInput 
        className={className}
        type="text" 
        name="date" 
        placeholder="Date"
        onChange={handle_change}
        onBlur={update_parent}
        value={display_date}
        pattern=".*\S+.*" 
        {...props}
        />
    )
}