import { useSetTokens, useAlerts } from "../../organisms/app/AppContext"
import Styles from "./Auth.module.css"
import {useState} from 'react'
import axios from 'axios'

export default function UpdatePassword() {
    const [form_data, set_form_data] = useState({})
    const set_tokens = useSetTokens()
    const {new_alert} = useAlerts()

    function update_form_data(event) {
        set_form_data({
            ...form_data, [event.target.name]: event.target.value
        })
    }
    
    async function update_password(event) {
        event.preventDefault()
        let response = undefined
        try {
            response = await axios.post("/auth/update_password", {
                old_password: form_data.current_password,
                new_password: form_data.new_password
            })
            set_form_data({})
            set_tokens()
        } catch (error) {
            response = error.response
        } finally {
            new_alert(response.status, response.headers.reason)
        }
    }

    return(
        <div className={Styles.center}>
            <div className={`${Styles.auth}`}>
                <header><h1>Update Password</h1></header>
                <hr />
                <form onSubmit={update_password}>
                    <input 
                        type="password"
                        id="current-password" 
                        name="current_password" 
                        autoComplete="current-password" 
                        placeholder="Old Password" 
                        value={form_data.current_password || ""}
                        onChange={update_form_data}
                        required/>
                    <input 
                        type="password" 
                        id="new-password" 
                        name="new_password" 
                        autoComplete="new-password" 
                        placeholder="New Password" 
                        value={form_data.new_password || ""} 
                        onChange={update_form_data}
                        required />
                    <input type="submit" id="submit" className={Styles.submit} value="Change Password"/>
                </form>
            </div>
        </div>
    )
}