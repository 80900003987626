import Styles from "./Inputs.module.css"
import { forwardRef } from 'react'

export default forwardRef(function Input({ value, onChange, onFocus, className, readonly, ...props }, ref) {
   function handle_change(event) {
      onChange && onChange(event)
   }

   function handle_focus(event) {
      if (readonly) event.target.blur()
      onFocus && onFocus(event)
   }

   return <input className={`${Styles.input} ${readonly && Styles.readonly} ${className}`} ref={ref} value={value} onChange={handle_change} onFocus={handle_focus} tabIndex={readonly ? -1 : props.readonly} {...props} />
})