import DatePicker from '../../atoms/controls/DatePicker'
import Button from '../../atoms/controls/Button'
import Input from '../../atoms/controls/Input'
import { formatInTimeZone } from "date-fns-tz"
import { useAlerts } from "../app/AppContext"
import Styles from './Forms.module.css'
import { addHours } from "date-fns"
import { useState } from 'react'
import axios from 'axios'


export default function QueueTimesForm() {
    const [data, set_data] = useState({})
    const { new_alert } = useAlerts()
    const [submitting, set_submitting] = useState(false)

    function update_data(property, value) {
        set_data((prev_data) => {
            return { ...prev_data, [property]: value }
        })
    }

    async function submit_data(event) {
        event.preventDefault()
        set_submitting(true)

        let response
        try {
            response = await axios.post("/api/queue_times", { data })
            set_data({})
        } catch (error) {
            response = error.response
            console.log(error)
        } finally {
            new_alert(response?.status, response?.headers?.reason)
        }
        set_submitting(false)
    }

    return (
        <form className={`${Styles.form}`} onSubmit={submit_data}>
            <div className={Styles.row}>
                <DatePicker
                    onDateChange={(date) => { update_data("date", date) }}
                    date={data.date}
                    autoComplete="off"
                    required
                />
            </div>
            <div className={Styles.group}>
                <header>Queue time in minutes</header>
                {
                    Array.from({ length: 7 }).map((_, index) => {
                        const time = formatInTimeZone(addHours(new Date(0, 0, 0, 9, 30), index), "Singapore", "hh:mma")
                        return (
                            <div>
                                <Input
                                    name={time}
                                    type="number"
                                    inputMode="numeric"
                                    min={0}
                                    step={1}
                                    max={1000}
                                    placeholder={time}
                                    onChange={(event) => { update_data(event.target.name, event.target.value) }}
                                    value={data[time] || ""}
                                    required={Object.keys(data).filter(key => key != "date").every(key => data[key] == null || data[key] == "")}
                                />
                            </div>
                        )
                    })
                }
            </div>

            <input type="submit" disabled style={{ display: "none" }} aria-hidden="true" /> {/* this is required to prevent user from submitting form with enter button */}
            <Button type="submit" disabled={submitting}>Submit</Button>
        </form>
    )
}