import React, { useContext, useEffect, useState, useRef, useLayoutEffect } from "react"
import allowed_routes from "../../utils/RoutesConfig"
import Login from "../../pages/auth/Login"
import Alerts from '../alerts/Alerts'
import Styles from "./App.module.css"
import { gsap } from "gsap"
import axios from "axios"

const TokensContext = React.createContext()
const RoutesContext = React.createContext()
const AlertsContext = React.createContext()

export function useSetTokens() {
    return useContext(TokensContext)
}

export function useRoutes() {
    return useContext(RoutesContext)
}

export function useAlerts() {
    return useContext(AlertsContext)
}

export default function AppContext({ children }) {
    const [tokens, set_tokens] = useState()
    const [tokens_refreshing, set_tokens_refreshing] = useState(false)
    const [failed_requests_queue, set_failed_requests_queue] = useState([])
    const [refresh_token_error, set_refresh_token_error] = useState()
    const [routes, set_routes] = useState([])
    const [alerts, set_alerts] = useState([])
    const login_screen = useRef()
    const timeline = useRef()

    useLayoutEffect(() => {
        const context = gsap.context(() => {
            timeline.current && timeline.current.progress(0).kill();
            timeline.current = gsap.timeline({ defaults: { duration: 1, ease: "power1.inOut" } })
                .delay(0.25)
                .fromTo(`.${Styles.login}`, { y: 0 }, { y: "-100vh" })
                .fromTo(`.${Styles.blur}`, { backgroundColor: "rgba(242, 242, 249, 1)", backdropFilter: "blur(4px)" }, { backgroundColor: "rgba(255, 255, 255, 0)", backdropFilter: "blur(0px)" }, "<")
                .set(`.${Styles.blur}`, { pointerEvents: "none" })
        }, login_screen);
        return () => {
            context.revert()
        };
    }, []);

    useEffect(() => {
        const auth_token = sessionStorage.getItem("auth_token")
        const refresh_token = sessionStorage.getItem("refresh_token")

        if (auth_token != null && refresh_token != null) {
            set_tokens({ auth_token, refresh_token })
        } else {
            // request tokens from other tabs
            window.localStorage.setItem('TOKEN_REQUEST', Date.now())
            window.localStorage.removeItem('TOKEN_REQUEST')
        }
    }, [])

    useEffect(() => {
        function handle_local_storage_events(event) {
            if (event.key === 'TOKEN_REQUEST' && event.newValue != null && tokens?.auth_token != null) {
                window.localStorage.setItem('TOKEN_DATA', JSON.stringify(tokens))
                window.localStorage.removeItem('TOKEN_DATA')
            }
            if (event.key === 'TOKEN_DATA' && event.newValue != null && tokens?.auth_token == null) {
                set_tokens(JSON.parse(event.newValue))
            }
            if (event.key === 'TOKEN_PUSH' && event.newValue != null) {
                set_tokens(JSON.parse(event.newValue))
            }
            if (event.key === 'TOKEN_CLEAR' && event.newValue != null && tokens?.auth_token != null) {
                set_tokens()
            }
        }
        window.addEventListener("storage", handle_local_storage_events)

        if (tokens?.auth_token) {
            window.localStorage.setItem('TOKEN_PUSH', JSON.stringify(tokens))
            window.localStorage.removeItem('TOKEN_PUSH')
            sessionStorage.setItem("auth_token", tokens.auth_token)
            axios.defaults.headers.common["authorization"] = `Bearer ${tokens.auth_token}`
        }
        if (tokens?.refresh_token) {
            sessionStorage.setItem("refresh_token", tokens.refresh_token)
        }
        if (tokens === "LOGOUT") {
            window.localStorage.setItem('TOKEN_CLEAR', Date.now())
            window.localStorage.removeItem('TOKEN_CLEAR')
            set_tokens()
        }

        timeline.current.timeScale(!tokens?.auth_token ? 2 : 1).reversed(!tokens?.auth_token)
        update_routes()

        return (() => {
            window.removeEventListener("storage", handle_local_storage_events)
        })
    }, [tokens?.auth_token])

    useEffect(() => {
        if (failed_requests_queue.length > 0 && !tokens_refreshing) {
            failed_requests_queue.forEach((failed_request_promise) => {
                if (tokens?.auth_token) {
                    failed_request_promise.resolve(tokens.auth_token)
                }
                else {
                    failed_request_promise.reject(refresh_token_error)
                }
            })
            set_failed_requests_queue([])
            set_refresh_token_error()
        }

        axios.interceptors.response.handlers = []
        axios.interceptors.response.use(
            response => response,
            async (error) => {
                if (error?.response?.status === 401 && error?.response?.headers?.reason === "you are not logged in") {
                    const original_request = error.config
                    if (!original_request._retry && tokens.refresh_token) {
                        return new Promise((resolve, reject) => {
                            set_failed_requests_queue((current_queue) => [...current_queue, { resolve, reject }])

                            if (!tokens_refreshing) {
                                set_tokens_refreshing(true)
                                axios.get("/auth/refresh_token", {
                                    headers: {
                                        authorization: `Bearer ${tokens.refresh_token}`
                                    }
                                }).then(({ data }) => {
                                    set_tokens(data)
                                }).catch((refresh_error) => {
                                    set_tokens("LOGOUT")
                                    set_refresh_token_error(refresh_error)
                                    new_alert(refresh_error.response.status, refresh_error.response.headers.reason)
                                }).then(() => {
                                    set_tokens_refreshing(false)
                                })
                            }
                        }).then((new_token) => {
                            original_request._retry = true
                            original_request.headers.authorization = `Bearer ${new_token}`
                            return axios(original_request)
                        }).catch((retry_error) => {
                            return Promise.reject(retry_error)
                        })
                    } else {
                        return Promise.reject(error)
                    }
                } else {
                    return Promise.reject(error)
                }
            }
        )

        return (() => {
            axios.interceptors.response.handlers = []
        })
    }, [tokens, tokens_refreshing])

    async function update_routes() {
        const updated_routes = await allowed_routes()
        set_routes(updated_routes)
    }

    function new_alert(code, message) {
        const handle = [...Array(5)].map(() => Math.random().toString(16)[2]).join('').toUpperCase()
        if (alerts.length > 0 && alerts[alerts.length - 1].code === code && alerts[alerts.length - 1].message === message) {
            return set_alerts((alerts) => [...alerts.slice(-3, -1), { handle, code, message }])
        }
        set_alerts((alerts) => [...alerts.slice(-2), { handle, code, message }])
    }

    function clear_alert(handle_to_delete) {
        set_alerts((alerts) => alerts.filter(({ handle }) => handle !== handle_to_delete))
    }

    return (
        <TokensContext.Provider value={set_tokens}>
            <RoutesContext.Provider value={routes}>
                <AlertsContext.Provider value={{ new_alert, clear_alert }}>
                    <Alerts className={Styles.alerts} alerts={alerts} />
                    <div ref={login_screen}>
                        <div className={Styles.blur} />
                        <div className={Styles.login}>
                            <Login />
                        </div>
                    </div>
                    {children}
                </AlertsContext.Provider>
            </RoutesContext.Provider>
        </TokensContext.Provider>
    )
}