import { isValid, format, startOfMonth, startOfDay, addMonths, addDays, startOfWeek, isWeekend, isToday, isEqual, getWeeksInMonth, weeksToDays, previousDay, differenceInDays, isSameMonth } from "date-fns"
import { useState, useEffect, forwardRef } from "react"
import Styles from "./Calendar.module.css"
import Button from "../controls/Button"

export default forwardRef(function Calendar({ className, date, weekStartsOnMonday=true, onDateChange, ...props}, ref) {
    const [view_month, set_view_month] = useState(startOfMonth(new Date()))

    useEffect(() => {
        if (isValid(date)) {
            set_view_month(startOfMonth(date))
        }
    }, [date])

    function change_date(date) {
        onDateChange && onDateChange(date)
    }

    return (
        <div className={className} ref={ref} {...props}>
            <div className={Styles.widget}>
                <div className={Styles.month}>
                    <Button onClick={() => set_view_month((current_view_date) => addMonths(current_view_date, -1))} tabIndex={-1}>{"<"}</Button>
                    <div>{format(view_month, "MMM yyyy")}</div>
                    <Button onClick={() => set_view_month((current_view_date) => addMonths(current_view_date, 1))} tabIndex={-1}>{">"}</Button>
                </div>
                <div className={Styles.calendar}>
                    {Array.from({ length: 7 }, (_, i) => <div className={Styles.header} key={i}>{format(addDays(startOfWeek(view_month), i + 1*weekStartsOnMonday), "EEEEE")}</div>)}
                    {Array.from({ length: weeksToDays(getWeeksInMonth(view_month, {weekStartsOn: 1*weekStartsOnMonday}))}, (_, i) => {
                        let first_date = previousDay(view_month, 1*weekStartsOnMonday)
                        first_date = differenceInDays(view_month, first_date) === 7 ? view_month : first_date

                        const button_date = addDays(first_date, i)
                        return <Button type="button" className={
                            `${isWeekend(button_date) ? Styles.weekend : ""} ${isToday(button_date) ? Styles.today : ""} ${isEqual(button_date, date) ? Styles.selected : ""} ${!isSameMonth(button_date, view_month) ? Styles.other_month : ""}`
                        } onClick={() => {
                            change_date(button_date)
                        }} tabIndex={-1}>{format(button_date, "d")}</Button>
                    })}
                </div> 
                <Button onClick={() => change_date(startOfDay(new Date()))} tabIndex={-1}>Today</Button>
            </div>
        </div>
    )
})