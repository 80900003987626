import { useRoutes } from '../app/AppContext'
import Styles from './Navbar.module.css'
import Logout from '../logout/Logout'
import {Link} from 'react-router-dom'

export default function Navbar() {
    const routes = useRoutes()
    
    return(
        <nav className={Styles.navbar}>
            <div className={Styles.navbar_items}>
                <div className={Styles.logo}>
                    <Link to="/" />
                    <img src="/lpq.png" alt="logo"></img>
                    <span>lpq.codes</span>
                </div>
                <div className={Styles.menu_items}>
                    <div>
                        <header>
                            <span>Modules</span>
                        </header>
                        <div className={Styles.dropdown_items}>
                            {   routes?.length? 
                                routes.map(({path, title}) => {
                                    if (title == null) return null
                                    return <Link key={path} to={path}>{title}</Link>
                                }) : null
                            }
                        </div>
                    </div>
                </div>
                <Logout />
            </div>
        </nav>
    )
}