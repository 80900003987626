import { useAlerts } from "../../organisms/app/AppContext"
import { useEffect, useState } from 'react'
import Styles from "./Auth.module.css"
import axios from 'axios'

export default function CreateAccount() {
    const { new_alert } = useAlerts()
    const [form_data, set_form_data] = useState({})
    const [account_roles, set_account_roles] = useState([])
    const [account_organisations, set_account_organisations] = useState([])
    const [account_sites, set_account_sites] = useState([])

    useEffect(() => {
        async function get_data() {
            try {
                set_account_roles((await axios.get("/api/account_roles")).data)
                set_account_organisations((await axios.get("/api/account_organisations")).data)
                set_account_sites((await axios.get("/api/account_sites")).data)
            } catch (error) {
                console.log(error)
            }
        }

        get_data()
    }, [])

    function update_form_data(event) {
        set_form_data((prev_form_data) => {
            return { ...prev_form_data, [event.target.name]: event.target.value }
        })
    }

    async function create_account(event) {
        event.preventDefault()
        let response
        try {
            response = await axios.post("/auth/create_account", {
                username: form_data.username,
                password: form_data.password,
                role: form_data.role,
                organisation: form_data?.organisation,
                site_id: form_data?.site_id,
                deputy_id: form_data?.staff_id
            })
            set_form_data({})
        } catch (error) {
            console.log(error)
            response = error.response
        } finally {
            new_alert(response.status, response.headers.reason)
        }
    }

    return (
        <div className={Styles.center}>
            <div className={`${Styles.auth}`}>
                <header><h1>Create Account</h1></header>
                <hr />
                <form onSubmit={create_account}>
                    <input
                        type="text"
                        id="username"
                        name="username"
                        autoComplete="off"
                        placeholder="Username"
                        value={form_data.username || ""}
                        onChange={update_form_data}
                        required />
                    <input
                        type="password"
                        id="password"
                        name="password"
                        autoComplete="new-password"
                        placeholder="Password"
                        value={form_data.password || ""}
                        onChange={update_form_data}
                        required />
                    <select
                        id="role"
                        name="role"
                        onChange={update_form_data}
                        value={form_data.role || ""}
                        required>
                        <option hidden disabled value="">Role</option>
                        {account_roles.map((role) => {
                            return <option key={role} value={role}>{role}</option>
                        })}
                    </select>
                    {
                        ["EA"].includes(form_data.role) ?
                            <select
                                id="organisation"
                                name="organisation"
                                onChange={update_form_data}
                                value={form_data.organisation || ""}
                                required>
                                <option hidden disabled value="">Organisation</option>
                                {account_organisations.map((organisation) => {
                                    return <option key={organisation} value={organisation}>{organisation}</option>
                                })}
                            </select> :
                            null
                    }
                    {
                        ["FA", "Site", "MSP"].includes(form_data.role) ?
                            <select
                                id="site_id"
                                name="site_id"
                                onChange={update_form_data}
                                value={form_data.site_id || ""}
                                required>
                                <option hidden disabled value="">Site</option>
                                {account_sites.map((site) => {
                                    return <option key={site.id} value={site.id}>{site.name}</option>
                                })}
                            </select> :
                            null
                    }
                    {
                        ["FA", "Site", "Admin", "SPO", "SCT"].includes(form_data.role) ?
                            <input
                                type="text"
                                id="deputy_id"
                                name="deputy_id"
                                autoComplete="off"
                                placeholder="Deputy ID"
                                value={form_data.deputy_id || ""}
                                onChange={update_form_data} /> :
                            null
                    }
                    <input type="submit" id="submit" className={Styles.submit} value="Create" />
                </form>
            </div>
        </div>
    )
}