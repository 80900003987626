import DatePicker from '../../atoms/controls/DatePicker'
import TextInput from '../../atoms/controls/TextInput'
import TextArea from '../../atoms/controls/TextArea'
import Button from "../../atoms/controls/Button"
import { useState, useEffect } from 'react'
import Styles from './Forms.module.css'
import axios from 'axios'

export default function AbsentReportFormRow({ data, update_data, delete_row, staff_list, leave_types, shifts }) {
    const [hrms_shift, set_hrms_shift] = useState()
    const [hrms_leave, set_hrms_leave] = useState()

    useEffect(() => {
        if (!(data.staff_id && data.date)) {
            set_hrms_shift()
            set_hrms_leave()
            return
        }

        axios.get(`/api/staff_scheduled_shift`, {
            params: {
                staff_id: data.staff_id,
                date: data.date
            }
        }).then(({ data }) => set_hrms_shift(data))

        axios.get(`/api/staff_scheduled_leave`, {
            params: {
                staff_id: data.staff_id,
                date: data.date
            }
        }).then(({ data }) => set_hrms_leave(data))

    }, [data.staff_id, data.date])

    return (
        <div className={`${Styles.row}`}>
            <div className={Styles.group}>
                <TextInput
                    name="staff_name"
                    list="staff_list"
                    placeholder="Staff Name"
                    onChange={(event) => {
                        update_data("staff_name", event.target.value)
                        update_data("staff_id", staff_list.find(({ name }) => name === event.target.value)?.id)
                    }}
                    value={data.staff_name || ""}
                    autoComplete='off'
                />
                <datalist id="staff_list" >
                    {staff_list.map(({ name }) => <option key={name} value={name} />)}
                </datalist>
                <TextInput
                    name="staff_id"
                    placeholder="Staff ID"
                    value={data.staff_id || ""}
                    readonly
                    required={Object.values(data).some((value) => value != null && value !== "")}
                />
            </div>

            <div className={Styles.date}>
                <DatePicker
                    onDateChange={(date) => {
                        update_data("date", date)
                    }}
                    date={data.date}
                    required={Object.values(data).some((value) => value != null && value !== "")}
                    title="This field is required"
                    autoComplete="off"
                />
            </div>

            <div className={Styles.group}>
                <TextInput
                    name="shift"
                    list="shifts"
                    placeholder="Shift"
                    onChange={(event) => update_data("shift", event.target.value)}
                    value={data.shift || ""}
                    required={Object.values(data).some((value) => value != null && value !== "")}
                    autoComplete='off'
                />
                <datalist id="shifts">
                    {shifts.map((shift) => <option value={shift} key={shift}>{shift}</option>)}
                </datalist>
                <TextInput
                    name="hrms_shift"
                    placeholder="HRMS Shift"
                    value={hrms_shift || ""}
                    readonly
                />
            </div>

            <div className={Styles.group}>
                <TextInput
                    name="leave_type"
                    list="leave_types"
                    placeholder="Status"
                    onChange={(event) => update_data("leave_type", event.target.value)}
                    value={data.leave_type || ""}
                    required={Object.values(data).some((value) => value != null && value !== "")}
                    autoComplete='off'
                />
                <datalist id="leave_types">
                    {leave_types.map((leave_type) => <option value={leave_type} key={leave_type}>{leave_type}</option>)}
                </datalist>
                <TextInput
                    name="hrms_leave"
                    placeholder="HRMS Leave"
                    value={hrms_leave || ""}
                    readonly
                />
            </div>

            <div>
                <TextArea
                    name="remarks"
                    placeholder="Remarks"
                    maxlength={1000}
                    onChange={(event) => update_data("remarks", event.target.value)}
                    onBlur={(event) => update_data("remarks", event.target.value.trim())}
                    value={data.remarks || ""}
                    autoComplete="off"
                />
            </div>

            <div className={Styles.delete}>
                <Button onClick={delete_row} tabIndex={-1}>Delete</Button>
            </div>
        </div>
    )
}