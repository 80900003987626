import DeleteForm from '../organisms/forms/DeleteForm'
import PageStyle from './pages.module.css'

export default function DeleteDeputyData() {
    return (
        <main>
            <div className={`section card ${PageStyle.display}`}>
                <header>
                    <h2>Delete Deputy Data</h2>
                </header>
                <hr />
                <DeleteForm />
            </div>
        </main>
    )
}