import DatePicker from "../../atoms/controls/DatePicker"
import Button from "../../atoms/controls/Button"
import { useAlerts } from "../app/AppContext"
import Styles from "./Forms.module.css"
import { addDays } from "date-fns"
import { useState } from "react"
import axios from "axios"

export default function AbsentReportControls() {
    const [start_date, set_start_date] = useState(addDays(Date.now(), -6))
    const [end_date, set_end_date] = useState(addDays(Date.now(), 0))
    const [submitting, set_submitting] = useState(false)
    const { new_alert } = useAlerts()

    async function trigger_report(event) {
        event.preventDefault()
        set_submitting(true)
        console.log(start_date)
        console.log(end_date)
        await axios.get("/admin/report/absent_report", { params: { start_date, end_date } })
        new_alert(200, "report requested")
        set_submitting(false)
    }

    return (
        <form className={`${Styles.form}`} onSubmit={trigger_report}>
            <div className={Styles.row}>
                <div>
                    <DatePicker
                        onDateChange={(date) => { set_start_date(date) }}
                        date={start_date}
                        autoComplete="off"
                        required
                    />
                </div>
                <div>
                    <DatePicker
                        onDateChange={(date) => { set_end_date(date) }}
                        date={end_date}
                        autoComplete="off"
                        required
                    />
                </div>
            </div>

            <input type="submit" disabled style={{ display: "none" }} aria-hidden="true" /> {/* this is required to prevent user from submitting form with enter button */}
            <Button type="submit" disabled={submitting}>Run</Button>
        </form>
    )
}
