import Select from "../../atoms/controls/Select"
import { useState, useEffect } from "react"
import Styles from "./Forms.module.css"
import axios from 'axios'

export default function SiteSelector({ className, value, onSelect, ...props }) {
    const [staff_sites, set_staff_sites] = useState([])

    useEffect(() => {
        async function get_data() {
            try {
                set_staff_sites((await axios.get("/api/staff_sites")).data)
            } catch (error) {
                console.log(error)
            }
        }

        get_data()
    }, [])

    useEffect(() => {
        if (staff_sites.length === 1) onSelect(staff_sites[0])
    }, [staff_sites])

    return (
        <Select className={`${className} ${Styles.site_selector}`} placeholder={"Select Site"} value={value} display={value?.name ? value.name : value?.id} onSelect={onSelect} {...props}>
            {staff_sites.map((site) => <option key={site.id} value={site}>{site?.name ? site.name : site.id}</option>)}
        </Select>
    )
}