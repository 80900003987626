import Styles from "./Inputs.module.css"
import { useState } from "react"

export default function Select({ className, value, display, placeholder, onSelect, children, ...props }) {
    const [show_dropdown, set_show_dropdown] = useState(false)

    function handle_change(value) {
        onSelect && onSelect(value)
        set_show_dropdown(false)
    }

    return (
        <div className={`${Styles.select} ${className}`} tabIndex={0}>
            <div className={Styles.input} onClick={() => set_show_dropdown((show) => !show)}>
                <div className={Styles.value}>{display ? display : value}</div>
                <div className={value ? `${Styles.placeholder} ${Styles.hidden}` : `${Styles.placeholder}`}>{placeholder}</div>
            </div>

            {show_dropdown ?
                <div className={`${Styles.options}`} {...props}>
                    {children.map((child) => {
                        const { props: { value: child_value, children: child_children, ...child_props } } = child
                        return <div key={child_children} className={Styles.option} onClick={() => handle_change(child_value)} {...child_props}>{child_children}</div>
                    })}
                </div> :
                null
            }
        </div>
    )
}