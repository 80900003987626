import { useState, useEffect, useRef } from 'react'
import Styles from "./CollapseContainer.module.css"

export default function CollapseContainer({collapse, className, children}) {
    const content_ref = useRef()
    const container_ref = useRef()
    const [height, set_height] = useState(0)
    
    useEffect(() => {
        const resize_observer = new ResizeObserver((element) => {
            if (collapse) {
                set_height(0)
            } else {
                const content_height = Object.values(element[0].target.children).reduce((sum, child) => {
                    return sum += child.getBoundingClientRect().height + parseFloat(window.getComputedStyle(child).marginTop) + parseFloat(window.getComputedStyle(child).marginBottom)
                }, 0)
                const container_height = parseFloat(window.getComputedStyle(container_ref.current).maxHeight)

                set_height(Math.min(content_height, container_height))
            }
        })
        resize_observer.observe(content_ref.current)
        return (() => {
            resize_observer.disconnect()
        })
    }, [height, collapse])
    
    return (
        <div className={`${Styles.collapse} ${className}`} ref={container_ref} style={{height: `${height}px`}}>
            <div className={Styles.shrinkwrapper} ref={content_ref}>
                {children}
            </div>
        </div>
    )
}