import { faFileArrowDown, faCopy } from '@fortawesome/free-solid-svg-icons'
import AbsentReportForm from '../organisms/forms/AbsentReportForm'
import { useAlerts } from "../organisms/app/AppContext"
import DatePicker from '../atoms/controls/DatePicker'
import IconButton from "../atoms/controls/IconButton"
import Tooltip from "../atoms/tooltip/Tooltip"
import Button from "../atoms/controls/Button"
import PageStyle from './pages.module.css'
import Table from '../atoms/table/Table'
import { format } from 'date-fns'
import { useState } from 'react'
import axios from 'axios'

export default function AbsentReport() {
    const [refreshing, set_refreshing] = useState(false)
    const [absent_data, set_absent_data] = useState()
    const [start_date, set_start_date] = useState()
    const [end_date, set_end_date] = useState()
    const { new_alert } = useAlerts()

    async function refresh_data() {
        try {
            set_refreshing(true)

            const params = {}
            if (start_date) params.start_date = start_date
            if (end_date) params.end_date = end_date

            const response = await axios.get("/api/absent_report", { params })
            set_absent_data(response.data)
            set_refreshing(false)
        } catch (error) {
            set_refreshing(false)
            console.log(error)
            console.log(error.response)
        }
    }

    async function download_report() {
        if (!absent_data?.csv) return new_alert(700, "nothing to download")
        const link = document.createElement("a")
        link.href = URL.createObjectURL(new Blob([absent_data.csv]))
        link.download = `absent_report_${format(Date.now(), "yyyy-MM-dd-hhmmss")}.csv`
        link.click()
    }

    async function copy_report() {
        if (!absent_data?.tsv) return new_alert(700, "nothing to copy")
        navigator.clipboard.writeText(absent_data.tsv)
        new_alert(200, "copied to clipboard")
    }

    return (
        <main>
            <div className="section card">
                <AbsentReportForm />
            </div>
            <div className={`section card ${PageStyle.display}`}>
                <header>
                    <h2>Absent History</h2>
                    <div className={PageStyle.query_options}>
                        <DatePicker placeholder="From" date={start_date} onDateChange={set_start_date} />
                        <DatePicker placeholder="To" date={end_date} onDateChange={set_end_date} />
                        <Button onClick={refresh_data}>Refresh</Button>
                    </div>
                    <div className={PageStyle.data_controls}>
                        <Tooltip tooltip="copy to clipboard"><IconButton className={PageStyle.icon} icon={faCopy} onClick={copy_report} /></Tooltip>
                        <Tooltip tooltip="download csv"><IconButton className={PageStyle.icon} icon={faFileArrowDown} onClick={download_report} /></Tooltip>
                    </div>
                </header>
                <hr />
                {!refreshing ?
                    <Table data={absent_data?.json} options={{ header: { "Staff": true, "Date": true, "Status": true } }} /> :
                    <div>Fetching Historical Data...</div>
                }
            </div>
        </main>
    )
}