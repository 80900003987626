import DatabaseControls from "../organisms/forms/DBControls"
import PageStyle from './pages.module.css'

export default function Database() {
    return (
        <main>
            <div className={`section card ${PageStyle.display}`}>
                <header>
                    <h2>Database Controls</h2>
                </header>
                <hr />
                <DatabaseControls />
            </div>
        </main>
    )
}