import { useEffect, useRef, useState } from 'react'
import Input from './Input'

export default function TextInput({ value, onChange, onFocus, readonly, ...props }) {
   const [cursor, setCursor] = useState(null)
   const ref = useRef(null)

   useEffect(() => {
      const input = ref.current
      if (input && document.activeElement === input) input.setSelectionRange(cursor, cursor)
   }, [ref, cursor, value])

   function handle_change(event) {
      setCursor(event.target.selectionStart)
      onChange && onChange(event)
   }

   function handle_focus(event) {
      if (readonly) event.target.blur()
      onFocus && onFocus(event)
   }

   return <Input ref={ref} value={value} onChange={handle_change} onFocus={handle_focus} readonly={readonly} {...props} />
}